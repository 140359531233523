// ** React Imports
import { Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// ** Redux Imports
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { registerSW } from 'virtual:pwa-register'


// ** React Toastify
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Spinner, Center } from '@chakra-ui/react'

// ** Service Worker
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// ** Lazy load app
registerSW({ immediate: true })
const LazyApp = lazy(() => import('./App'))
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 1
    }
  }
})


const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Center h='50%'> <Spinner size='xl' /></Center>}>
        <QueryClientProvider client={queryClient}>
          <LazyApp />
        </QueryClientProvider>
        <ToastContainer />
      </Suspense>
    </Provider>
  </BrowserRouter>
)
