// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userCollectibles = createSlice({
  name: 'userCollectibles',
  initialState: {
    collectibles: [],
    expiredCollectibles: [],
    ownerData: null,
    searchQuery: '',
    loadingCollectibles: false
  },
  reducers: {
    setCollectibles: (state, action) => {
      state.collectibles = action.payload
    },
    setExpiredCollectibles: (state, action) => {
      state.expiredCollectibles = action.payload
    },
    setOwnerData: (state, action) => {
      state.ownerData = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setLoadingUserCollectibles: (state, action) => {
      state.loadingCollectibles = action.payload
    },
    resetUserCollectibles: (state, action) => {
      state.collectibles = []
      state.expiredCollectibles = []
      state.ownerData = null
      state.searchQuery = ''
      state.loadingCollectibles = false
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCollectibles, setExpiredCollectibles, setOwnerData, setSearchQuery, setLoadingUserCollectibles, resetUserCollectibles } =
  userCollectibles.actions
export default userCollectibles.reducer
