// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
const cookiePrefix = import.meta.env.VITE_APP_COOKIE_PREFIX

const initialUser = () => {
  const item = localStorage.getItem(`${cookiePrefix}userData`)
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const initialAuthToken = () => {
  const item = localStorage.getItem(`${cookiePrefix}authToken`)
  return item ? item : null
}

const initialLogo = () => {
  const item = localStorage.getItem(`${cookiePrefix}logo`)
  if (item) {
    return item
  } else {
    const account = item ? JSON.parse(item) : {}
    return account.image_url ? account.image_url : null
  }
}

const initialSwapAccount = () => {
  const item = localStorage.getItem(`${cookiePrefix}userData`)
  const authToken = localStorage.getItem(`${cookiePrefix}authToken`)
  if (item) {
    const itemJson = JSON.parse(item)
    if (itemJson.access_keys.access_token === authToken) {
      return itemJson.account
    } else if (itemJson.linked_accounts) {
      const findAccount = itemJson.linked_accounts.find((account) => account.access_keys.access_token === authToken)
      return findAccount ? findAccount : null
    } else {
      localStorage.setItem(`${cookiePrefix}authToken`, itemJson.access_keys.access_token)
      return itemJson.account
    }
  } else {
    return null
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: initialUser(),
    authToken: initialAuthToken(),
    swapAccount: initialSwapAccount(),
    logo: initialLogo()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload.userData
      state.authToken = action.payload.authToken
      const userData = action.payload.userData
      userData.image_url = action.payload.userData.account.image_url
      localStorage.setItem(`${cookiePrefix}userData`, JSON.stringify(userData))
      localStorage.setItem(`${cookiePrefix}logo`, action.payload.userData.account.image_url)
      localStorage.setItem(`${cookiePrefix}authToken`, action.payload.authToken)
    },
    handleSwapAccount: (state, action) => {
      state.swapAccount = action.payload.swapAccount
      localStorage.setItem(`${cookiePrefix}logo`, action.payload.swapAccount.image_url)
    },
    handleLogout: (state) => {
      state.userData = {}
      state.authToken = null
      state.logo = null

      // ** Remove user, accessToken from localStorage
      localStorage.removeItem(`${cookiePrefix}userData`)
      localStorage.removeItem(`${cookiePrefix}authToken`)
      localStorage.removeItem(`${cookiePrefix}logo`)
    }
  }
})

export const { handleLogin, handleSwapAccount, handleLogout } = authSlice.actions

export default authSlice.reducer
