// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const airdrop = createSlice({
  name: 'airdrop',
  initialState: {
    airdropList: []
  },
  reducers: {
    setEmptyAirdrops: (state) => {
      state.airdropList = []
    },

    addAirdrops: (state, action) => {
      const airdrops = action.payload ? action.payload : []
      state.airdropList = state.airdropList.concat(airdrops)
    },

    addAirdrop: (state, action) => {
      const airdrop = action.payload
      state.airdropList.push(action.payload)
    },

    editAirdrop: (state, action) => {
      const airdrop = action.payload
      const index = state.airdropList.findIndex(
        (tempAirdrop) => tempAirdrop.airdrop_id === airdrop.airdrop_id
      )

      state.airdropList.splice(index, 1, airdrop)
    },

    deleteAirdrop: (state, action) => {
      const airdrop = action.payload

      const index = state.airdropList.findIndex(
        (tempAirdrop) => tempAirdrop.airdrop_id === airdrop.airdrop_id
      )

      state.airdropList.splice(index, 1)
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  addAirdrops,
  addAirdrop,
  editAirdrop,
  deleteAirdrop,
  setEmptyAirdrops
} = airdrop.actions
export default airdrop.reducer
